const copyButtons = document.querySelectorAll('.copy');

copyButtons.forEach(button => {
	button.addEventListener('click', () => {
		const box = button.closest('.inner-wrapper');
		const detailsTextElement = box.querySelector('.text-to-copy');

		const textToCopy = document.createTextNode(detailsTextElement.textContent);

		const textArea = document.createElement('textarea');
		textArea.appendChild(textToCopy);
		document.body.appendChild(textArea);

		textArea.select();
		document.execCommand('copy');

		document.body.removeChild(textArea);

		button.classList.remove('copy');
		button.classList.add('copied-text');
		button.style.display = 'block'; 
		setTimeout(() => {
			button.classList.remove('copied-text');
			button.classList.add('copy');
		}, 1000);
	});
});
