document.addEventListener('turbo:load', function () {
	const anchors = document.querySelectorAll('a[href="#card"], a[href="#ukraine"], a[href="#swift"]');

	for (let anchor of anchors) {
		anchor.addEventListener('click', function (e) {
			e.preventDefault();
			const blockID = anchor.getAttribute('href').substr(1);

			document.getElementById(blockID).scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});
		});
	}
});