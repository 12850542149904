import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

import "./controllers"

import "trix"
import "@rails/actiontext"

require("./packs/header")
require("./packs/copy")
require("./packs/scroll")
require("./packs/admin")
