document.addEventListener('turbo:load', function () {
	var openMenu = document.querySelector('.menu-open');
	var menu = document.querySelector('.menu-body');
	var darken = document.querySelector('.darken');

	function toggleMenu() {
		menu.classList.toggle('active');
		openMenu.classList.toggle('active');
		if (menu.classList.contains('active')) {
			darken.style.display = 'block';
		} else {
			darken.style.display = 'none';
		}
	}
	openMenu.addEventListener('click', function (event) {
		toggleMenu();
		event.stopPropagation();
	});

	menu.addEventListener('click', function (event) {
		event.stopPropagation();
	});

	document.addEventListener('click', function (event) {
		var isClickInsideMenu = menu.contains(event.target);

		if (!isClickInsideMenu && menu.classList.contains('active')) {
			toggleMenu();
		}
	});
});


const currentBox = document.querySelector('.current-box.desktop');
const langMenu = document.querySelector('.lang-menu.desktop');
if (currentBox) {
	currentBox.addEventListener('click', function (event) {
		langMenu.classList.toggle('open');
		currentBox.classList.toggle('open');
		event.stopPropagation(); 
	});
	
	const currentBoxMb = document.querySelector('.current-box.adaptive');
	const langMenuMb = document.querySelector('.lang-menu.adaptive');
	
	currentBoxMb.addEventListener('click', function (event) {
		langMenuMb.classList.toggle('open');
		currentBoxMb.classList.toggle('open');
		event.stopPropagation(); 
	});
	
	document.addEventListener('click', function () {
		langMenu.classList.remove('open');
		currentBox.classList.remove('open');
	
		langMenuMb.classList.remove('open');
		currentBoxMb.classList.remove('open');
	});
}
