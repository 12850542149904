document.addEventListener("DOMContentLoaded", function() {
    var cardNumberInputs = document.querySelectorAll(".validate-card");

    cardNumberInputs.forEach(function(input) {
      input.addEventListener("input", function(event) {
        var value = event.target.value;
        var newValue = value.replace(/\D/g, "");
        newValue = newValue.slice(0, 16);
        var formattedValue = newValue.replace(/(\d{4})(?=\d)/g, "$1 ");
        event.target.value = formattedValue;
      });
    });
  });
